<template>
  <button type="button" class="nb">
    <span class="nb__icon" v-html="props.iconSvg"></span>
    <span class="nb__label">{{ props.label }}</span>
  </button>
</template>

<script setup>
const props = defineProps({
  iconSvg: String,
  label: String
})
</script>

<style lang="scss" scoped>
.nb {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 0.16px;
  width: 100%;
  border-radius: 8px;
  transition: background-color 0.2s;
  color: var(--Text, #000);

  &:hover {
    background-color: var(--Banner-5, rgba(0, 0, 0, 0.05));
  }

  &__icon {
    :deep(svg) {
      display: block;
      position: relative;
      top: -1px;
      path {
        stroke: var(--Text, #000);
        stroke-width: 1;
      }
    }
  }
}
</style>
